export function admitedCharts(value) {
  if (/[^-\w+' \u00f1\u00d1\u00E0-\u00FC-ZÀ-ÿ]/.test(value) ) {
    return 'Solo puede contener letras, números y los siguientes caracteres:  -  ,  _  ,  ¨  ,  \' ';
  }
  return true;
}

export function required(value) {
  return (value != null && value !== '') || 'Este campo es requerido';
}

export function admitedOnlyLetter(value) {
  if (!/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(value) ) {
    return 'Solo puede contener letras';
  }
  return true;
}

export function noZeroStart (number) {
  if (/^0[0-9]*$/.test(number) ) {
    return 'No puede empezar con 0';
  }
  return true;
}

export function onlyInteger (evt, value, largo) {
  evt = (evt) ? evt : window.event;
  const charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode < 48 || charCode > 57) {
    evt.preventDefault();
  } else {
    return String(value).length <= ((largo <= 14 && largo) || 14) || evt.preventDefault();
  }
}

export default function (Vue) {
  Vue.mixin({
    methods: {
      $chkAdmitedCharts: admitedCharts,
      $chkRequired: required,
      $chkAdmitedOnlyLetter: admitedOnlyLetter,
      $noZeroStart: noZeroStart,
      $onlyInteger: onlyInteger
    }
  });
}
