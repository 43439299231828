import DateDB from "@demre/date-db";
import store from "../store";

export function getPayload() {
  const token = window.localStorage.getItem('token');
  if (token) {
    const payload = token.split('.')[1];
    const payloadStr = window.atob(payload);
    return JSON.parse(payloadStr);
  }
  return '';
}

export function arrayToObject(array, fxKey, fxValue) {
  return array.reduce((obj, item) => {
    obj[fxKey(item)] = fxValue(item);
    return obj;
  }, {});
}

function checkMime(bytes, mime) {
  const l = Math.min(mime.mask.length, bytes.length);
  for (let i = 0; i < l; ++i) {
    if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
      return false;
    }
  }
  return true;
}

function getMime(file) {
  const mimes = [
    {
      mime: 'image/jpeg',
      pattern: [0xFF, 0xD8, 0xFF, 0x00, 0x00, 0x00, 0x00, 0x00],
      mask: [0xFF, 0xFF, 0xFF, 0x00, 0x00, 0x00, 0x00, 0x00],
      ext: 'jpg'
    },
    {
      mime: 'image/png',
      pattern: [0x89, 0x50, 0x4E, 0x47, 0x00, 0x00, 0x00, 0x00],
      mask: [0xFF, 0xFF, 0xFF, 0xFF, 0x00, 0x00, 0x00, 0x00],
      ext: 'png'

    },
    {
      mime: 'application/pdf',
      pattern: [0x25, 0x50, 0x44, 0x46, 0x00, 0x00, 0x00, 0x00],
      mask: [0xFF, 0xFF, 0xFF, 0xFF, 0x00, 0x00, 0x00, 0x00],
      ext: 'pdf'
    },
    {
      mime: 'video/mp4',
      pattern: [0x00, 0x00, 0x00, null, 0x66, 0x74, 0x79, 0x70, 0x6D, 0x70, 0x34, 0x32],
      mask: [0xFF, 0xFF, 0xFF, 0x00, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
      ext: 'mp4'
    }
  ];

  //return new Promise((resolve, reject) => {
  return new Promise((resolve) => {
    const blob = file.slice(0, 8);
    const reader = new FileReader();

    if (blob.type == undefined) {
      return resolve({ mime: 'unknown', ext: 'unknown' });
    }

    reader.onloadend = function (e) {
      if (e.target.readyState === FileReader.DONE) {
        const bytes = new Uint8Array(e.target.result);
        for (let i = 0; i < mimes.length; ++i) {
          if (checkMime(bytes, mimes[i])) {
            return resolve({ 'mime': mimes[i].mime, 'ext': mimes[i].ext });
          }
        }
        return resolve({ mime: 'unknown', ext: 'unknown' });
      }
    };
    //TODO: on error reject (should unknown returns, reject cases really?)
    reader.readAsArrayBuffer(blob);
  });
}

async function acceptedMime(file) {
  const accepted_mimes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
  ];
  const mime = await getMime(file);
  return accepted_mimes.includes(mime.mime);
}

async function acceptedMimeVideo(file) {
  const accepted_mimes = [
    'video/mp4'
  ];
  if (accepted_mimes.includes(file.type)){
    const mime = await getMime(file);
    return accepted_mimes.includes(mime.mime);
  } 
  return false;
}

export function validarRut(rut) {
  rut = rut.replace(/\./g, '').replace(/-/g, '').toUpperCase();
  
  let dv = rut.slice(-1);
  
  let rutNumerico = parseInt(rut.slice(0, -1), 10);
  
  let suma = 0;
  let multiplo = 2;
  for (let i = rutNumerico.toString().length - 1; i >= 0; i--) {
      suma += rutNumerico.toString().charAt(i) * multiplo;
      multiplo = multiplo === 7 ? 2 : multiplo + 1;
  }
  let resto = suma % 11;
  let dvEsperado = 11 - resto;
  if (dvEsperado === 10) dvEsperado = 'K';
  else if (dvEsperado === 11) dvEsperado = 0;

  if (dv !== dvEsperado.toString()) {
      return false;
  }
  return true;
}

export function fechaCambioRendicion(fase) {

  const fechaFinInscripcion =  new DateDB(store.state.config[fase].finInscripcion);
  const fechaCambio = new DateDB(window.DATA.fechaCambioFinInscripcion);
  if (fase == 6) {
    return fechaFinInscripcion.format('dddd DD de MMMM de YYYY hasta las HH:mm horas');
  }
  //entra si no se ha llegado a la fecha o si ya se paso la fecha pero no se extendio el cierre
  if (window.DATA.cambiarFechaFinInscripcion ) {
    return fechaCambio.format('dddd DD de MMMM de YYYY hasta las HH:mm horas');
  }
  else {
    return fechaFinInscripcion.format('dddd DD de MMMM de YYYY hasta las HH:mm horas');
  }
}

export default {
  acceptedMimeVideo,
  acceptedMime,
  getMime
};