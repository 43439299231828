import Vue from 'vue';
export default {
  setConfig: (state, config) => state.config = config,
  setMe: (state, me) => {
    state.me = me;
  },
  setLogged: (state, logged) => state.logged = logged,
  setFormulariosValidos: (state, validacion) => state.formulariosValidos = validacion,
  switchAccesibilidadSordos: (state) => state.accesibilidadSordos = !state.accesibilidadSordos,
  setFaseSeleccionada: (state, fase) => state.faseSeleccionada = fase,
  setAnioProcesoAdmision: (state, anio) => state.anioProcesoAdmision = anio,
  setCuposFaseSeleccionada: (state, total) => state.totalCupos = total,
  updateNormativa: (state, fase) => Vue.set(state.normativa, fase, true),
  setNormativa: (state, normativa) => {
    normativa.forEach( ({fspCodigo}) => state.normativa[fspCodigo] = true );
  },
  setBeca: (state, beca) => state.beca = beca,
  setPruebasAccesoEspecial: (state, pruebas) => state.pruebasAccesoEspecial = pruebas,
  setFormulario:  (state, formulario) => state.formulario = formulario,
  setCuestionario:  (state, cuestionario) => state.cuestionario = cuestionario,
  setDialogGeograficos: (state, val) => state.dialogGeograficos = val,
  
};
