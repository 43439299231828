import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import postulante from "./modules/postulante";
import inscripcion from "./modules/inscripcion";
import session from "@demre/client-common/src/components/jwt-refresher/session";

Vue.use(Vuex);

const state = {
  config: {},
  me: {},
  logged: false,
  formulariosValidos: false,
  accesibilidadSordos: false,
  faseSeleccionada: null,
  anioProcesoAdmision: null,
  totalCupos: null,
  normativa: {},
  beca: false,
  pruebasAccesoEspecial: [],
  formulario: {},
  dialogGeograficos: false,
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state,
  mutations,
  modules: {
    postulante,
    inscripcion,
    session,
  },
});
