import store from '../store';

async function request(req) {
  await store.dispatch('session/ensureSession');

  if (typeof req === 'string') {
    req = {
      query: `query {${req}}`
    };
  }
  const token = window.localStorage.getItem('token');
  const response = await fetch(process.env.VUE_APP_SERVER_HOST + '/api/graphql', {
    method: 'post',
    redirect: 'manual',
    headers: {
      'Content-Type': 'text/plain',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(req)
  });

  if (response.status === 0) {
    window.onbeforeunload = null;
    window.location.reload();
    throw Error(`Ocurrió un error inesperado. (${response.status})`);
  }
  const data = await response.json();

  if (typeof data.errors !== 'undefined') {
    const msgError = [];
    let invalidSession = false;
    data.errors.forEach(error => {
      //verificar si es un DemreError
      const code = error.extensions.code;
      if (/^E[0-9]{4}[A-Z]$/.test(code)) {
        msgError.push(error.message);
        invalidSession = invalidSession || /^E002[456]B$/.test(code);
      }
    });
    if (invalidSession) {
      await store.dispatch('session/logout');
    }
    if (msgError.length) {
      const error = new Error(msgError.join('<br/>'));
      error.isDemreError = true;
      error.original = data.errors;
      throw error;
    }
    throw Error(`Ocurrió un error inesperado. (${response.status})`);
  }

  return data.data;
}

export default {
  request
};