import Vue from "vue";
import vuetify from "./plugins/vuetify";
import can from "./utils/can";
import chk from "./utils/chk";
import archivos from "@demre/client-common/src/plugins/archivos";
import store from "./store";
import { getPayload, arrayToObject } from "./utils/util";
import querysinit from "./utils/querysinit";
import validaImpedimentos from "./utils/impedimentos";
window.DATA = {};

Vue.use(archivos);
Vue.use(chk);
Vue.config.productionTip = false;

async function init() {
  const {
    postulante,
    configuracion,
    correlativoSesion,
    licencia,
    pruebas,
    infoFases,
    permisos,
    anioProcesoAdmision,
    fechaCambioFinInscripcion,
    cambiarFechaFinInscripcion
  } = await querysinit.cargaInicial();
  Vue.use(can, permisos);
  postulante.inscrito = {};
  postulante.persona.correlativoSesion = correlativoSesion;

  if (pruebas.length) {
    const pruebasInscritasPorFase = pruebas.reduce(
      (obj, { fspCodigo, pruCodigo }) => {
        obj[fspCodigo] = obj[fspCodigo] || [];
        obj[fspCodigo].push(pruCodigo);
        return obj;
      },
      {}
    );
    postulante.inscrito = pruebasInscritasPorFase;
  }
  const procesosVigentes = configuracion.filter((c) => c.procesoVigente);
  let config = arrayToObject(
    procesosVigentes,
    (x) => x.fspCodigo,
    (x) => x
  );
  const totalProcesosVigentes = procesosVigentes.length;
  postulante.esAccesoEspecial = false;

  if (totalProcesosVigentes == 2) {
    const { faseInvierno, faseVerano } = infoFases;
    if (!config[faseInvierno].inscripcionVigente && !postulante.inscrito[faseInvierno]) {
      if (config[faseInvierno].accesoRezagado) {
        const tipo = await querysinit.getAccesoEspecial(faseInvierno);
        if (tipo != "rezagado") {
          delete config[faseInvierno];
        }
      } else {
        delete config[faseInvierno];
      }
    }
    if (!config[faseVerano].inscripcionVigente && !postulante.inscrito[faseVerano]) {
      if (config[faseVerano].accesoRezagado) {
        const tipo = await querysinit.getAccesoEspecial(faseVerano);
        if (tipo != "rezagado" && postulante.inscrito[faseInvierno]) {
          infoFases.cierreVerano = config[faseVerano].finInscripcion;
          delete config[faseVerano];
        }
      } else if(postulante.inscrito[faseInvierno]) {
        delete config[faseVerano];
      }
    }
  } else if (totalProcesosVigentes == 0) {
    //no hay procesos activos, determinar si estoy en periodo anticipado
    const periodoAnticipado = configuracion.find((cfg) => cfg.accesoAnticipado);
    if (periodoAnticipado) {
      const tipo = await querysinit.getAccesoEspecial(
        periodoAnticipado.fspCodigo
      );
      if (tipo == "acceso anticipado") {
        postulante.esAccesoEspecial = true;
        config = {
          [periodoAnticipado.fspCodigo]: {
            inscripcionCerrada: false,
            puedeActualizarSede: true,
            inscripcionVigente: true,
            procesoVigente: true,
            tbkVigente: true,
            nombreRendicion: periodoAnticipado.nombreRendicion,
            rdnInvierno: "S",
            finActualizacionRendicion:
              periodoAnticipado.finActualizacionRendicion,
          },
        };
      }
    } else {
      const mensaje = "No existen procesos de inscripción vigentes";
      await querysinit.insertLogsAcceso(
        postulante.insTipo,
        mensaje,
        correlativoSesion,
        permisos
      );
      throw new Error(mensaje);
    }
  }
  infoFases.faseActiva = Number(Object.keys(config)[0]);
  const impedimento = await validaImpedimentos(
    postulante,
    infoFases,
    config,
    licencia,
    correlativoSesion,
    anioProcesoAdmision
  );
  if (impedimento) {
    throw new Error(impedimento);
  }

  if (store.getters["session/isLoggedIn"]()) {
    //log de acceso al portal
    await querysinit.insertLogsAcceso(
      postulante.insTipo,
      "",
      correlativoSesion,
      permisos
    );
    store.commit("setEscolaridad", postulante.escolaridad);
    store.commit("setInscrito", postulante.inscrito);
    store.commit("setConfig", config);
    store.commit("setNormativa", postulante.normativa);
    store.commit("setBeca", postulante.beca);
    store.commit("setMe", postulante.persona);
    const inviernoActiva =
      config[infoFases.faseInvierno] != undefined &&
      config[infoFases.faseInvierno].procesoVigente;
    if (!postulante.inscrito[infoFases.faseInvierno] && inviernoActiva) {
      querysinit.cupos();
    }
  }
  const { username } = getPayload();
  store.commit("setAnioProcesoAdmision", anioProcesoAdmision);
  window.DATA.profileUrl = `${process.env.VUE_APP_CADES_CLIENT}/profile?appId=${process.env.VUE_APP_CADES_ID}&username=${username}`;
  window.DATA.postulante = postulante;
  window.DATA.fechaCambioFinInscripcion = fechaCambioFinInscripcion;
  window.DATA.cambiarFechaFinInscripcion = cambiarFechaFinInscripcion;
}
function salirAdvertencia(e) {
  const mensaje = "saliendo de aplicación";
  e.returnValue = mensaje;
  return mensaje;
}

window.onbeforeunload = salirAdvertencia;

store
  .dispatch("session/load")
  .then(init)
  .then(async () => {
    const { default: App } = await import(
      "./App" /* webpackChunkName: 'inscripcion paes' */
    );
    const { default: router } = await import(
      "./router" /* webpackChunkName: 'router' */
    );
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch(async (err) => {
    const mensaje = err.message;
    const { default: Impedimento } = await import(
      "./Impedimento.vue" /* webpackChunkName: 'impedimento' */
    );
    new Vue({
      vuetify,
      store,
      render: (h) => h(Impedimento, { props: { mensaje } }),
    }).$mount("#app");
  });

window.store = store;
