import DateDB from '@demre/date-db';
import querysinit from '../utils/querysinit';

const mensajeSinRegistroEnPersonasSinLicencia = `
  Tus datos personales y antecedentes académicos no están registrados en nuestra base de datos. <br><br>
  Para regularizar tu situación y proceder con la inscripción para rendir la Prueba de Acceso a la Educación Superior (PAES),
  así como para obtener tu puntaje NEM y puntaje Ranking de notas, debes solicitar tu(s) certificado(s) de enseñanza media, vía web en el siguiente
  <a href="https://solicitud-certificados.mineduc.cl/solicitud-certificados/certificadoAnualEstudio" rel="noopener noreferrer" target="_blank">enlace</a>
  o llamando al Call Center Mineduc 600 600 2626. Si lo que necesitas es solicitar el “Reconocimiento de estudios realizados en el extranjero”,
  haz este trámite en el siguiente <a href="https://www.ayudamineduc.cl/ficha/reconocimiento-y-convalidacion-de-estudios-basicos-y-medios-no-profesionales-realizados-en-el" rel="noopener noreferrer" target="_blank">enlace</a>.
  Debes considerar que este trámite puede tener un plazo máximo de respuesta de 20 días hábiles.<br><br>
  Además, para dejar constancia de tu intento de registro en la plataforma, ingresa al siguiente <a href="https://demre.cl/inscripcion/como-inscribirme-paes-regular-p2025/datos-personales-no-registrados" rel="noopener noreferrer" target="_blank">enlace</a>
  y sigue las instrucciones que allí se te entregarán. Ante cualquier consulta sobre este proceso contacta a la MESA DE AYUDA DEMRE al fono:(+56)2 2978 3806.
`;

async function validaImpedimentos(postulante, infoFases, config, licencia, correlativoSesion, anioProcesoAdmision) {
  let mensaje = null;
  const { nombreRendicion, inscripcionVigente, finInscripcion } = config[infoFases.faseActiva];
  const estaInscrito = !!postulante.inscrito[infoFases.faseActiva];
  const mensajeCierrePortal = `Te informamos que no estás inscrita/o para la ${nombreRendicion} Admisión ${anioProcesoAdmision} (el periodo de inscripción finalizó el ${new DateDB(finInscripcion).format('DD de MMMM YYYY')}). Por lo tanto, no puedes ingresar a este Portal. <a href="https://youtu.be/3-54EjGWuyM" target="_blank"><img style="border-radius: 50%" src="https://archivos.demre.cl/public/pesd_logo.png"/></a>`;
  if (Object.keys(config) == 0) { //ninguna inscripcion esta vigente
    const mensaje = `Te informamos que no estás inscrita/o para la PAES Regular Admisión ${anioProcesoAdmision} (el periodo de inscripción finalizó el ${new DateDB(infoFases?.cierreVerano).format('DD de MMMM YYYY')}). Por lo tanto, no puedes ingresar a este Portal.<a href="https://youtu.be/3-54EjGWuyM" target="_blank"><img style="border-radius: 50%" src="https://archivos.demre.cl/public/pesd_logo.png"/></a>`;
    await querysinit.insertLogsAcceso(postulante.insTipo, mensaje, correlativoSesion);
    return mensaje;
  }

  if (!inscripcionVigente) {
    const periodoRezagado = config[infoFases.faseActiva].accesoRezagado;
    if (periodoRezagado) {
      const tipo = await querysinit.getAccesoEspecial(infoFases.faseActiva);
      if (tipo == 'rezagado') {
        postulante.esAccesoEspecial = true;
      } else if (!estaInscrito) {
        return mensajeCierrePortal;
      }
    } else if (!estaInscrito) {
      return mensajeCierrePortal;
    }
  }

  if (!postulante.persona) {
    const mensaje = 'No existe persona registrada';
    await querysinit.insertLogsAcceso(postulante.insTipo, mensaje, correlativoSesion);
    return mensajeSinRegistroEnPersonasSinLicencia;
  }

  //sin licencia de 4° medio - invierno
  if (infoFases.faseInvierno == infoFases.faseActiva && !licencia) {
    await querysinit.insertLogsAcceso(postulante.insTipo, 'Sin licencia de 4° medio', correlativoSesion);
    return mensajeSinRegistroEnPersonasSinLicencia;
  }

  return mensaje;
}

export default validaImpedimentos;

