import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from '../i18n/vuetify/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0070C0',
        secondary: '#002060',
        error: '#EA1D76',
        warning: '#FF9C33',
        info: '#C4D600',
        success: '#00B09A',
        nota: '#b2e5f6',
      },
      dark: {
        primary: '#0070C0',
        warning: '#FFC000',
        error: '#FF66FF',
        nota: '#201c1c',
        success: '#008000',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    values: {
      alt_route: 'M13 10l-2-2 3-1 1 2-2 1zm5 1l4-4-4-4v3h-2v2h2zm0 10l4-4-4-4v3c-4 0-5-1-6-3l-1-1 1-1-2-2c0 1-1 2-3 2H2v2h5l4 2c1 1 2 3 7 3v3z',
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
});
