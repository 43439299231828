import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

const state = {
  datosInscrito: {
    insTipo: "",
    anioEgreso: "",
    sedCodigo: "",
    sadCodigo: "",
    numeroArchivo: "",
    discapacidad: "",
    discapacidadesPostulante: [],
    recibioAdecuacionColegio: "",
    telefonoContactoEmergenciaPesd:"", 
    nombreContactoEmergenciaPesd:"",
    bloqueoPsd: "",
    aceptaContactoDemre: "",
    textoAdecuacion: "",
    textoAdecuacionUnidad: "",
    bloqueoNombreSocial: "",
    bloqueoIpe: "",
    flagPreCargaVerano: "",
    publicaDistincion: [],
    institucionesDistincion: [],
  },
  escolaridad: null,
  direccion: {
    calle: "",
    nroCalle: "",
    regCodigo: "",
    comCodigo: "",
    celular: "",
    telefonoAdicional: "",
    detalleDireccion: "",
  },
  recluso: null,
  archivospsd: {
    compin: [],
    medico: [],
    regnac: [],
    formest: [],
    infest: [],
    documentacionPesd: [],
    videosordera: [],
  },
  cartapsd: null,
  cuestionario_dirty: false, // indica si cuestionario requiere ser guardado
  pruebasPagar: [],
  totalPagar: null,
  antecedentesGeograficosContactoOK: false,
  antecedentesAcademicosOk: false,
  antecedentesPersonalesFamiliaresOk: false,
  cuestionarioOk: false,
  consentimientoOk: false,
  antecedentesRendicionOk: false,
  bloqueoSinCuposDialog: false,
  runValidaciones: false,
  archivosNombreSocial: {},
  archivosNombreSocialOriginal: {},
  archivosIpe: {},
  archivosIpeOriginal: {},
  puntajesVigentes: [],
};

const getters = {
  getDatosInscritoField(state) {
    return getField(state.datosInscrito);
  },
  getDireccionField(state) {
    return getField(state.direccion);
  },
  getRespuestaByStepperPregunta: (state) => (stepperId, preguntaId) => {
    return state[`stepper${stepperId}`][preguntaId];
  },
  getRespuestaStepper: (state) => (stepperId) => {
    return state[`stepper${stepperId}`];
  },
  promocionActual(state) {
    return state.datosInscrito.insTipo < 5;
  },
};

const mutations = {
  runValidaciones(state, data) {
    state.runValidaciones = data;
  },
  updateDataFormulario(state, { stepperId, preguntaId, newValue }) {
    return (state[`stepper${stepperId}`][preguntaId] = newValue);
  },
  setDatosFormulario(state, { data, stepperId }) {
    if (stepperId == 5) {
      state.stepper5.sedCodigo = data;
      if (data) {
        const sede = window.ITEMS.sedes.find((sede) => sede.sedCodigo == data);
        state.stepper5.regCodigo = sede.regCodigo;
      }
    } else {
      for (const k in data) {
        state[`stepper${stepperId}`][k] = data[k];
      }
    }
  },
  updateDatosInscritoField(state, field) {
    if(field.path == 'discapacidadesPostulante') {
      if(!field.value.includes(3)){
        if (state.archivospsd['videosordera']?.length > 0) {
          field.value.push(3);
          this.commit('error-notification', 'Debes borrar el video asociado a sordera');
        }
      }
    }
    updateField(state.datosInscrito, field);
  },
  updateDireccionField(state, field) {
    updateField(state.direccion, field);
  },
  setDatosInscrito(state, data) {
    state.datosInscrito = { ...state.datosInscrito, ...data };
  },
  setEscolaridad(state, data) {
    state.escolaridad = data;
  },
  setDireccion(state, data) {
    state.direccion = { ...state.direccion, ...data };
  },
  setRecluso(state, data) {
    state.recluso = data;
  },
  setArchivosPsd(state, data) {
    state.archivospsd = { ...state.archivospsd, ...data };
  },
  setArchivosPsdInicializar(state) {
    state.archivospsd = {
      compin: [],
      medico: [],
      regnac: [],
      formest: [],
      infest: [],
      documentacionPesd: [],
      videosordera: [],
    };
  },
  setArchivosNombreSocial(state, data) {
    state.archivosNombreSocial = data;
  },
  setArchsNombreSocialOriginal(state, data) {
    state.archivosNombreSocialOriginal = {
      ...state.archivosNombreSocialOriginal,
      ...data,
    };
  },
  setCartaPsd(state, data) {
    state.cartapsd = data;
  },
  setSteps(state, data) {
    for (let i = 1; i <= data; i++) {
      Vue.set(state, `stepper${i}`, {});
    }
  },
  updateArchivoPsd(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivospsd[tipoDoc]) {
      state.archivospsd[tipoDoc].push(data.newdata);
    } else {
      state.archivospsd[tipoDoc] = [data.newdata];
    }
  },
  updateArchivoNombreSocial(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivosNombreSocial[tipoDoc]) {
      state.archivosNombreSocial[tipoDoc].push(data.newdata);
    } else {
      state.archivosNombreSocial[tipoDoc] = [data.newdata];
    }
  },
  deleteArchivoPsd(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivospsd[tipoDoc]) {
      state.archivospsd[tipoDoc] = state.archivospsd[tipoDoc].filter(
        (v) => v.filename !== data.filename
      );
    }
  },
  deleteArchivoNombreSocial(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivosNombreSocial[tipoDoc]) {
      state.archivosNombreSocial[tipoDoc] = state.archivosNombreSocial[
        tipoDoc
      ].filter((v) => v.filename !== data.filename);
    }
  },
  deleteArchivoNombreSocialOriginal(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivosNombreSocialOriginal[tipoDoc]) {
      state.archivosNombreSocialOriginal[tipoDoc] =
        state.archivosNombreSocialOriginal[tipoDoc].filter(
          (v) => v.filename !== data.filename
        );
    }
  },
  deleteAllArchivosPsd(state) {
    state.archivospsd = {
      compin: [],
      medico: [],
      regnac: [],
      formest: [],
      infest: [],
      documentacionPesd: [],
      videosordera: [],
    };
  },
  updateNumeroArchivo(state, data) {
    state.datosInscrito.numeroArchivo = data;
  },
  updateBloqueoPsd(state, data) {
    state.datosInscrito.bloqueoPsd = data;
  },
  updateBloqueoNombreSocial(state, data) {
    state.datosInscrito.bloqueoNombreSocial = data;
  },
  updateBecaMineduc(state, data) {
    state.datosInscrito.becaMineduc = data;
  },
  setpruebasPagar(state, data) {
    state.pruebasPagar = data;
  },
  setDialogCupos(state, data) {
    state.bloqueoSinCuposDialog = data;
  },
  setTotalPagar(state, data) {
    state.totalPagar = data;
  },
  setAntecedentesGeograficosContactoOk(state, data) {
    state.antecedentesGeograficosContactoOK = data;
  },
  setAntecedentesAcademicosOk(state, data) {
    state.antecedentesAcademicosOk = data;
  },
  setAntecedentesPersonalesFamiliaresOk(state, data) {
    state.antecedentesPersonalesFamiliaresOk = data;
  },
  setCuestionarioOk(state, data) {
    state.cuestionarioOk = data;
  },
  setConsentimientoOk(state, data) {
    state.consentimientoOk = data;
  },
  setAntecedentesRendicionOk(state, data) {
    state.antecedentesRendicionOk = data;
  },
  setEstructuraFormulario(state, data) {
    for (const step of data) {
      state[`stepper${step.stepperId}`] = step.estructura;
    }
  },
  updateArchivoIdentificacionIpe(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivosIpe[tipoDoc]) {
      state.archivosIpe[tipoDoc].push(data.newdata);
    } else {
      state.archivosIpe[tipoDoc] = [data.newdata];
    }
  },
  setArchivosIpe(state, data) {
    state.archivosIpe = { ...state.archivosIpe, ...data };
  },
  setArchivosIpeOriginal(state, data) {
    state.archivosIpeOriginal = { ...state.archivosIpeOriginal, ...data };
  },
  deleteArchivoIpe(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivosIpe[tipoDoc]) {
      state.archivosIpe[tipoDoc] = state.archivosIpe[tipoDoc].filter(
        (v) => v.filename !== data.filename
      );
    }
  },
  deleteArchivoIpeOriginal(state, data) {
    const tipoDoc = data.tipoDoc;
    if (state.archivosIpeOriginal[tipoDoc]) {
      state.archivosIpeOriginal[tipoDoc] = state.archivosIpeOriginal[
        tipoDoc
      ].filter((v) => v.filename !== data.filename);
    }
  },
  updateBloqueoIpe(state, data) {
    state.datosInscrito.bloqueoIpe = data;
  },
  setPuntajesVigentes(state, data) {
    state.puntajesVigentes = data;
  },
};

export default {
  state,
  getters,
  mutations,
};
