import DateDB from '@demre/date-db';

const state = {
  inscrito: {},
  pruebasFase: [],
  localAsignado: {},
};

const meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ];

const dias =  ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];

const getters = {
  pruebasInscritas(state, getters, rootState) {
    const fase = rootState.faseSeleccionada;
    return fase && state.inscrito[fase] ? state.inscrito[fase] : [];
  },
  esInscrito(state, getters) {
    return !!getters.pruebasInscritas.length;
  },
  localAsignado(state, getters, rootState) {
    const fase = rootState.faseSeleccionada;
    const fspCodigo = state.localAsignado.local ? state.localAsignado.local.fspCodigo : -1;
    return state.inscrito[fase] && fase == fspCodigo ? state.localAsignado.local : null;
  },
  fechaInicioInscripcion(state, getters, rootState) {
    const date = new DateDB(rootState.config[rootState.faseSeleccionada]?.inicioProceso); 
    return `${dias[date.getDay()]} ${date.getDate()} de ${meses[date.getMonth()]} de ${date.getFullYear()} a las ${date.getUTCHours()}:${(date.getMinutes() < 10 ? '0' : '')}${date.getMinutes()} horas`;
  },
  fechaFinInscripcion(state, getters, rootState) {
    const date = new DateDB(rootState.config[rootState.faseSeleccionada]?.finInscripcion);
    return `${dias[date.getDay()]} ${date.getDate()} de ${meses[date.getMonth()]} de ${date.getFullYear()} a las ${date.getUTCHours()}:${(date.getMinutes() < 10 ? '0' : '')}${date.getMinutes()} horas`;
  },
  finActualizacionRendicion(state, getters, rootState) {
    const date = new DateDB(rootState.config[rootState.faseSeleccionada]?.finActualizacionRendicion);
    return `${date.getDate()} de ${meses[date.getMonth()]} de ${date.getFullYear()}`;
  }
};

const mutations = {
  setInscrito: (state, inscrito) => state.inscrito = inscrito,
  setInscritoFase(state, inscrito) {
    const pruebas = JSON.parse(JSON.stringify(state.inscrito)); // se hace esto para activar la reactividad de inscrito
    pruebas[this.state.faseSeleccionada] = inscrito;
    state.inscrito  = pruebas;
  },
  setPruebasFase(state, data) {
    state.pruebasFase = data;
  },
  setLocalAsignado: (state, local) => state.localAsignado = local,
};

export default {
  state,
  getters,
  mutations
};
